import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/navigation"
import Business from "../components/business"
import Instagram from "../components/instagram"
import Footer from "../components/footer"
import Image from "../components/image";


const IndexPage = ({ data, altData, altState }) => {
  if (altData) {
    data = altData
  }

  const [mobileSite, setMobileSite] = useState(altState ? altState : null)
  const restaurants = data.siteContents.edges
  const {
    meta_title,
    meta_description,
    meta_image,
  } = data.siteMeta.edges[0].node.frontmatter
  const taglineRestaurant = restaurants[0].node.frontmatter.tagline
  const taglineGelateria = restaurants[1].node.frontmatter.tagline

  return (
    <Layout>
      <SEO
        title={meta_title}
        description={meta_description}
        ogimage={meta_image.publicURL}
        state={mobileSite}
      />
      <Navigation
        tag1={taglineRestaurant}
        tag2={taglineGelateria}
        state={mobileSite}
        setSite={setMobileSite}
      >
        {restaurants.map((r, i) => {
          const { business } = r.node.frontmatter
          return (
            <Business
              key={i}
              businessClass={business}
              data={r.node.frontmatter}
              state={mobileSite}
            />
          )
        })}
      </Navigation>
      <Image />
      <Footer
        state={mobileSite}
        restaurant={restaurants[0].node.frontmatter}
        gelateria={restaurants[1].node.frontmatter}
        policy={restaurants[0].node.frontmatter.policy_file.publicURL}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    siteContents: allMarkdownRemark(
      filter: { frontmatter: { business: { ne: null } } }
      sort: { fields: frontmatter___business, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            intro_top
            intro_btm
            tagline
            about_desc
            business
            word_1
            word_2
            word_3
            menu_buttons {
              btn_title
              menu_file {
                publicURL
              }
            }
            address
            phone
            fb
            ig
            policy_file {
              publicURL
            }
          }
        }
      }
    }
    siteMeta: allMarkdownRemark(
      filter: { frontmatter: { business: { eq: null } } }
    ) {
      edges {
        node {
          frontmatter {
            meta_description
            meta_title
            meta_image {
              publicURL
            }
          }
        }
      }
    }
  }
`
 
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      insta1: file(relativePath: { eq: "insta1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insta2: file(relativePath: { eq: "insta2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insta3: file(relativePath: { eq: "insta3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insta4: file(relativePath: { eq: "insta4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      insta5: file(relativePath: { eq: "insta5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="Instagram">
      <a href="https://www.instagram.com/portobelloascotvale/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.insta1.childImageSharp.fluid} />
      </a>
      <a href="https://www.instagram.com/portobelloascotvale/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.insta2.childImageSharp.fluid} />
      </a>
      <a href="https://www.instagram.com/portobelloascotvale/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.insta3.childImageSharp.fluid} />
      </a>
      <a href="https://www.instagram.com/portobelloascotvale/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.insta4.childImageSharp.fluid} />
      </a>
      <a href="https://www.instagram.com/portobelloascotvale/" target="_blank" rel="noopener noreferrer">
        <Img fluid={data.insta5.childImageSharp.fluid} />
      </a>
    </div>
  )
}

export default Image

import React from "react"
import cx from "classnames"
import styles from "../cssmodules/footer.module.scss"
import Logo from "../svg/logo.inline.svg"
import Markr from "../svg/markr.inline.svg"
import Markg from "../svg/markg.inline.svg"
import { FaFacebookSquare } from "react-icons/fa"
import { AiOutlineInstagram } from "react-icons/ai"
const Footer = ({ state, restaurant, gelateria, policy }) => {
  return (
    <footer className={cx(styles.footer, !state && `hide_on_mobile`)}>
      <Logo className={styles.big_logo} />
      <div
        className={cx(styles.restaurant, state !== "gelateria" && styles.show)}
      >
        <Markr className={styles.logo_mark} />
        <Logo className={styles.logo_inner} />
        <div className={styles.socials}>
          {restaurant.fb.includes("facebook") && (
            <a href={restaurant.fb} target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare />
            </a>
          )}
          {restaurant.ig.includes("instagram") && (
            <a href={restaurant.ig} target="_blank" rel="noopener noreferrer">
              <AiOutlineInstagram />
            </a>
          )}
        </div>
        <div className={styles.colophon}>
          <p className={styles.policy}>
            <a href={policy}>Privacy policy</a> {new Date().getFullYear()}{" "}
            &copy; Portobello pty ltd
          </p>
          <p className={styles.credits}>
            website brewed by{" "}
            <a
              href="https://maltcreative.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              malt
            </a>
          </p>
        </div>
      </div>
      <div
        className={cx(styles.gelateria, state === "gelateria" && styles.show)}
      >
        <Markg className={styles.logo_mark} />
        <Logo className={styles.logo_inner} />
        <div className={styles.socials}>
          {gelateria.fb.includes("facebook") && (
            <a href={gelateria.fb} target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare />
            </a>
          )}
          {gelateria.ig.includes("instagram") && (
            <a href={gelateria.ig} target="_blank" rel="noopener noreferrer">
              <AiOutlineInstagram />
            </a>
          )}
        </div>
        <div className={styles.colophon}>
          <p className={styles.policy}>
            <a href={policy}>Privacy policy</a> {new Date().getFullYear()}{" "}
            &copy; Portobello pty ltd
          </p>
          <p className={styles.credits}>
            website brewed by{" "}
            <a
              href="https://maltcreative.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              malt
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, { useState, useEffect } from "react"
import cx from "classnames"
import Scrollchor from "react-scrollchor"
import styles from "../cssmodules/navigation.module.scss"
import Logo from "../svg/logo.inline.svg"
import Markr from "../svg/markr.inline.svg"
import Markg from "../svg/markg.inline.svg"

const Navigation = ({ tag1, tag2, state, setSite, children }) => {
  const [burger_is_open, toggleBurger] = useState(false)
  const [vheight, setVheight] = useState(false)
  useEffect(() => {
    const viewport_height = window.innerHeight
    setVheight(viewport_height)
  }, [vheight])
  return (
    <>
      <div className={cx(styles.intro_mobile, state && styles.hidden)}>
        <div
          onClick={() => setSite("restaurant")}
          onKeyDown={e => e.keyCode === 13 && setSite("restaurant")}
          role="button"
          tabIndex="0"
          className={styles.restaurant}
          style={{ height: `${vheight / 2}px` }}
        >
          <Logo className={styles.logo} />
          <Markr className={styles.marks} />
          <h3>{tag1}</h3>
          <div className={styles.button}>ENTER</div>
        </div>
        <div
          onClick={() => setSite("gelateria")}
          onKeyDown={e => e.keyCode === 13 && setSite("gelateria")}
          role="button"
          tabIndex="0"
          className={styles.gelateria}
          style={{ height: `${vheight / 2}px` }}
        >
          <div className={styles.button}>ENTER</div>
          <Logo className={styles.logo} />
          <Markg className={styles.marks} />
          <h3>{tag2}</h3>
        </div>
      </div>
      <div className={styles.burger_wrap}>
        <div
          onClick={() => toggleBurger(!burger_is_open)}
          onKeyDown={e => e.keyCode === 13 && toggleBurger(!burger_is_open)}
          role="button"
          tabIndex="0"
          className={cx(
            styles.hamburger,
            styles.hamburger__3dx,
            burger_is_open && styles.is_active
          )}
        >
          <div className={styles.hamburger_box}>
            <div className={styles.hamburger_inner}></div>
          </div>
        </div>
      </div>
      <nav
        className={cx(
          styles.main_nav,
          styles[`${state && state + "_nav"}`],
          burger_is_open && styles.is_active
        )}
      >
        <Logo className={styles.logo} />
        <ul className={styles.menu}>
          <li>
            <Scrollchor
              beforeAnimate={() => toggleBurger(false)}
              to={
                state === "gelateria" ? "#menu_gelateria" : "#menu_restaurant"
              }
              disableHistory
            >
              Menus
            </Scrollchor>
          </li>
          <li>
            <Scrollchor
              beforeAnimate={() => toggleBurger(false)}
              to={
                state === "gelateria" ? "#about_gelateria" : "#about_restaurant"
              }
              disableHistory
            >
              about us
            </Scrollchor>
          </li>
          <li>
            <Scrollchor
              beforeAnimate={() => toggleBurger(false)}
              to="#instafeed"
              disableHistory
            >
              social
            </Scrollchor>
          </li>
        </ul>
        <div className={styles.mark_wrap}>
          {state === "gelateria" ? (
            <div
              onClick={() => {
                setSite("restaurant")
                toggleBurger(false)
              }}
              onKeyDown={e =>
                e.keyCode === 13 && setSite("restaurant") && toggleBurger(false)
              }
              role="button"
              tabIndex="0"
            >
              <div className={styles.label}>Visit</div>
              <Markr className={styles.menu_icon_restaurant} />
              <p className={styles.restaurant_tag}>
                {tag1.split("•").join("\n")}
              </p>
            </div>
          ) : (
            <div
              onClick={() => {
                setSite("gelateria")
                toggleBurger(false)
              }}
              onKeyDown={e =>
                e.keyCode === 13 && setSite("gelateria") && toggleBurger(false)
              }
              role="button"
              tabIndex="0"
            >
              <div className={styles.label}>Visit</div>
              <Markg className={styles.menu_icon_gelateria} />
              <p className={styles.gelateria_tag}>
                {tag2.split("•").join("\n")}
              </p>
            </div>
          )}
        </div>
      </nav>
      {children}
    </>
  )
}

export default Navigation

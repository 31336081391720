import React from "react"
import styles from "../cssmodules/business.module.scss"
import cx from "classnames"
import Markdown from "markdown-to-jsx"
import Logo from "../svg/logo.inline.svg"
import Markr from "../svg/markr.inline.svg"
import Markg from "../svg/markg.inline.svg"
import Circle from "../svg/circle.inline.svg"
import Triangle from "../svg/triangle.inline.svg"
import AboutPizza from "../svg/about_pizza.inline.svg"
import AboutGelato from "../svg/about_gelato.inline.svg"
// import Uber from "../svg/uber.inline.svg"

const wrapInSpans = word => {
  let result = word
    .split("")
    .map(letter => `<span>${letter}</span>`)
    .join("")
  return { __html: result }
}

const Business = ({ businessClass, data, state }) => {
  const {
    intro_top,
    intro_btm,
    tagline,
    about_desc,
    menu_buttons,
    word_1,
    word_2,
    word_3,
    address,
    phone,
  } = data
  const showRestaurant =
    state !== null && state.startsWith("r") && businessClass.startsWith("r")
  const showGelateria =
    state !== null && state.startsWith("g") && businessClass.startsWith("g")
  return (
    <>
      <div
        className={cx(
          styles[businessClass],
          showRestaurant && styles.show,
          showGelateria && styles.show
        )}
      >
        <header>
          <Logo className={cx(styles.svg_white, styles.logo)} />
          <h4>
            {intro_top}
            <br />
            <span>{intro_btm}</span>
          </h4>
          {businessClass.startsWith("r") ? (
            <Markr className={styles.svg_mark_hero} />
          ) : (
            <Markg className={styles.svg_mark_hero} />
          )}
          <h3 className={styles.tagline}>{tagline}</h3>
        </header>
        <section id={`menu_${businessClass}`} className={styles.menu}>
          {businessClass.startsWith("r") ? (
            <Markr className={styles.svg_mark_menu} />
          ) : (
            <Markg className={styles.svg_mark_menu} />
          )}
          <div className={styles.wrapped}>
            {<h2 dangerouslySetInnerHTML={wrapInSpans(word_1)} />}
            <Circle className={styles.misc_icon} />
            {<h2 dangerouslySetInnerHTML={wrapInSpans(word_2)} />}
            <Triangle className={styles.misc_icon} />
            {<h2 dangerouslySetInnerHTML={wrapInSpans(word_3)} />}
          </div>
          <div className={cx(styles.button_container, styles.wrapped)}>
            {menu_buttons.length > 0 &&
              menu_buttons.map(({ menu_file, btn_title }, i) => (
                <a
                  className={styles.button}
                  key={i}
                  href={menu_file ? menu_file.publicURL : "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {btn_title}
                </a>
              ))}
            <Triangle className={styles.small_triangle} />
          </div>
        </section>
        <section id={`about_${businessClass}`} className={styles.about}>
          <div className={styles.wrapped}>
            {businessClass.startsWith("r") ? <AboutPizza /> : <AboutGelato />}
            <h3 className={styles.tagline}>{tagline}</h3>
            <br />
            <Markdown
              options={{ forceBlock: true }}
              className={styles.description}
            >
              {about_desc}
            </Markdown>
            <address>
              {address}
              <br />
              <span>{phone}</span>
            </address>
            <div className={styles.buttons}>
              {
                businessClass.startsWith("r") && (
                  <a
                    href="https://bookings.nowbookit.com/?accountid=b36f5d85-f139-45a8-8f64-23dbf5e24f44&venueid=5090&theme=light&colors=hex,0d5302"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.button}
                  >
                    ristorante bookings
                  </a>
                )
              }
              
              { /*
              <a
                href="https://www.ubereats.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Uber className={styles.uber} />
              </a>
              */}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Business
